import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import {
  Board as BaseBoard,
  TitleArea,
  FooterArea,
  ContentArea as BaseContentArea,
  FormRow,
} from "components/LayoutParts";
import Loading from "components/Loading";
import Select from "components/Select";
import TextForm from "components/TextForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import { MINUTES, TWO_DIGITS_HOURS } from "constants/time";
import { useUpdateCompany, useFindCompanyById, useDeleteCompany } from "pages/SyncPort/Company/features/api";
import { ALREADY_EXIST, ERROR, SUCCESS } from "pages/SyncPort/Company/features/utils/modalTemplate";
import { getNextHours } from "utils/dateTimeUtils";
import { twoDigits } from "utils/stringUtils";

// パーツ定義
const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;
const ContentArea = styled(BaseContentArea)`
  margin-bottom: 48px;
`;
const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;
const Label = styled.div`
  min-width: 160px;
  margin: 0 5px;
`;
const ErrorArea = styled.div<{
  isErrorDisplay: boolean;
}>`
  display: ${(props) => (props.isErrorDisplay ? "block" : "none")};
  position: absolute;
  white-space: nowrap;
  color: ${color.attention};
  margin: 2px 0;
`;
const ErrorMessage = styled.span`
  font-size: ${font.size12};
`;
const DeleteButtonWrap = styled.div`
  margin-left: auto;
`;
const OverWrapBaseBoard = styled(BaseBoard)`
  justify-content: space-between;
`;

const EditCompany = () => {
  // フォームの値
  const [name, setName] = useState("");
  const [branchName, setBrachName] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [faxNumber, setFaxNumber] = useState("");
  const [openingHour, setOpeningHour] = useState("00");
  const [openingMinute, setOpeningMinute] = useState("00");
  const [closingHour, setClosingHour] = useState("00");
  const [closingMinute, setClosingMinute] = useState("00");
  const [companyHp, setCompanyHp] = useState("");

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const changeBranchName = (e: React.ChangeEvent<HTMLInputElement>) => setBrachName(e.target.value);
  const changeAddress = (e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value);
  const changePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value);
  const changeFaxNumber = (e: React.ChangeEvent<HTMLInputElement>) => setFaxNumber(e.target.value);
  const changeCompanyHp = (e: React.ChangeEvent<HTMLInputElement>) => setCompanyHp(e.target.value);

  const navigate = useNavigate();

  const [modalConfig, setModalConfig] = useState<UserModalConfig>({
    onClickOutside: () => {
      return;
    },
    subtitle: "完了しました",
    main: {
      buttonType: "secondary",
      onClick: () => {
        return;
      },
      label: "閉じる",
    },
  });
  const [isVisible, setIsVisible] = useState(false);
  const showModal = (modalConfig?: UserModalConfig) => {
    modalConfig != null ? setModalConfig(modalConfig) : null;
    setIsVisible(true);
  };

  const hideModal = () => {
    setIsVisible(false);
  };

  const { id } = useParams();
  const { data: companyDetail } = useFindCompanyById({
    companyId: id ?? "",
  });
  const { updateCompany } = useUpdateCompany();

  const onSubmit = useCallback(async () => {
    const { status } = await updateCompany({
      id: id ?? "",
      name,
      branchName,
      address,
      phoneNumber,
      faxNumber,
      companyHp,
      openingHour,
      openingMinute,
      closingHour,
      closingMinute,
    });

    if (status !== 200) {
      if (status === 409) {
        return showModal(ALREADY_EXIST(() => hideModal()));
      }
      showModal(ERROR(() => hideModal()));
      return;
    }

    showModal(
      SUCCESS(() => {
        hideModal();
        navigate(`/sync_port/company/${id}`);
      })
    );
  }, [
    updateCompany,
    id,
    name,
    branchName,
    address,
    phoneNumber,
    faxNumber,
    companyHp,
    openingHour,
    openingMinute,
    closingHour,
    closingMinute,
    navigate,
  ]);

  const { deleteCompany } = useDeleteCompany();
  const onDelete = useCallback(async () => {
    const { status } = await deleteCompany({
      id: id ?? "",
    });

    if (status !== 200) {
      showModal(ERROR(() => hideModal()));
      return;
    }

    showModal(
      SUCCESS(() => {
        hideModal();
        navigate(`/sync_port/company`);
      })
    );
  }, [deleteCompany, id, navigate]);

  const selectAbleHours = useMemo(() => getNextHours(openingHour), [openingHour]);

  // バリデーション
  const isErrorName = name === "";
  const isErrorBranchName = branchName.length > 20;
  const isErrorAddress = address === "";
  const isErrorPhone = !/^0\d{9,10}$/.test(phoneNumber);
  const isErrorFax = faxNumber !== "" ? !/^0\d{9,10}$/.test(faxNumber) : false;
  const isErrorHp = companyHp !== "" ? !/^https?:\/\/[\w!?/+\-_~=;.,*&@#$%()'[\]]+$/.test(companyHp) : false;
  const isErrorTime = useMemo(() => {
    const opening = parseInt(openingHour) * 60 + parseInt(openingMinute);
    const closing = parseInt(closingHour) * 60 + parseInt(closingMinute);
    return opening > closing;
  }, [openingHour, openingMinute, closingHour, closingMinute]);
  const isError =
    isErrorName || isErrorBranchName || isErrorAddress || isErrorPhone || isErrorFax || isErrorHp || isErrorTime;

  const setForm = useCallback(() => {
    if (companyDetail == null) return;
    const { name, branchName, address, phoneNumber, faxNumber, companyHp, openingTime, closingTime } = companyDetail;

    setName(name);
    setBrachName(branchName ?? "");
    setAddress(address);
    setPhoneNumber(phoneNumber);
    setFaxNumber(faxNumber ?? "");
    setCompanyHp(companyHp ?? "");

    const startTimeSplit = openingTime ? openingTime.split(":") : ["00", "00"];
    setOpeningHour(startTimeSplit[0]);
    setOpeningMinute(startTimeSplit[1]);

    const endTimeSplit = closingTime ? closingTime.split(":") : ["00", "00"];
    setClosingHour(endTimeSplit[0]);
    setClosingMinute(endTimeSplit[1]);
  }, [companyDetail]);

  const onBackPage = useCallback(() => {
    navigate(`/sync_port/company/${id}`);
  }, [navigate, id]);

  useEffect(() => {
    setForm();
  }, [setForm]);

  return (
    <OverWrapBaseBoard>
      <div>
        <TitleArea>
          <Breadcrumb
            currentPageName="会社情報を編集"
            breadcrumbItems={[
              companyDetail != null
                ? {
                    pageName: `${companyDetail.name} ${companyDetail.branchName ?? ""}`,
                    onClick: () => onBackPage(),
                  }
                : { pageName: "読み込み中..." },
            ]}
          />
          <DeleteButtonWrap>
            <Button
              type="danger"
              onClick={() => onDelete()}
              label="この管理会社を削除"
              disabled={companyDetail == null}
              width="160px"
            />
          </DeleteButtonWrap>
        </TitleArea>
        {companyDetail == null ? (
          <Loading />
        ) : (
          <ContentArea>
            <FormRow>
              <Label>会社名</Label>
              <TextForm
                type="text"
                width="832px"
                value={name}
                placeholder="例）ザイマックス三田ビル"
                required
                onChange={changeName}
                errorMessage="会社名を入力してください"
                isError={isErrorName}
              />
            </FormRow>
            <FormRow>
              <Label>支店名 / 支社名</Label>
              <TextForm
                type="text"
                width="832px"
                value={branchName}
                placeholder="例）横浜支店"
                required
                onChange={changeBranchName}
                errorMessage="支店名 / 支社名は20文字で入力してください"
                isError={isErrorBranchName}
              />
            </FormRow>
            <FormRow>
              <Label>住所</Label>
              <TextForm
                type="text"
                width="832px"
                value={address}
                placeholder="例）京都府京都市東山区三条通南二筋目白川筋西入ル二丁目北木之元"
                required
                onChange={changeAddress}
                errorMessage="住所を入力してください"
                isError={isErrorAddress}
              />
            </FormRow>
            <FormRow>
              <Label>電話番号</Label>
              <TextForm
                type="text"
                width="350px"
                value={phoneNumber}
                placeholder="例）0368224474"
                required
                onChange={changePhoneNumber}
                errorMessage="電話番号は10桁もしくは11桁の半角数字で入力してください"
                isError={isErrorPhone}
              />
            </FormRow>
            <FormRow>
              <Label>FAX</Label>
              <TextForm
                type="text"
                width="350px"
                value={faxNumber}
                placeholder="例）0368224474"
                required
                onChange={changeFaxNumber}
                errorMessage="FAX番号は10桁もしくは11桁の半角数字で入力してください"
                isError={isErrorFax}
              />
            </FormRow>
            <FormRow>
              <Label>会社HP</Label>
              <TextForm
                type="text"
                width="350px"
                value={companyHp}
                placeholder="例）https://www.landit.co.jp/"
                required
                onChange={changeCompanyHp}
                errorMessage="会社HPは正しいURL形式で入力してください"
                isError={isErrorHp}
              />
            </FormRow>
            <FormRow style={{ position: "relative" }}>
              <Label>営業時間</Label>
              <div style={{ marginRight: "8px" }}>開始</div>
              <Select
                choices={TWO_DIGITS_HOURS}
                value={openingHour}
                width="100px"
                required
                onSelect={(selected) => {
                  setOpeningHour(twoDigits(selected));
                  selected == "24" ? setOpeningMinute("00") : null;
                }}
              />
              <div style={{ marginLeft: "8px", marginRight: "8px" }}>時</div>
              <Select
                choices={MINUTES}
                value={openingMinute}
                width="100px"
                required
                onSelect={(selected) => {
                  setOpeningMinute(openingHour != "24" ? selected : "00");
                }}
              />
              <div style={{ marginLeft: "8px" }}>分</div>
              <div style={{ marginLeft: "32px", marginRight: "8px" }}>終了</div>
              <Select
                choices={selectAbleHours}
                value={closingHour}
                width="100px"
                required
                onSelect={(selected) => {
                  setClosingHour(twoDigits(selected));
                  selected == "24" ? setClosingMinute("00") : null;
                }}
              />
              <div style={{ marginLeft: "8px", marginRight: "8px" }}>時</div>
              <Select
                choices={MINUTES}
                value={closingMinute}
                width="100px"
                required
                onSelect={(selected) => {
                  setClosingMinute(closingHour != "24" ? selected : "00");
                }}
              />
              <div style={{ marginLeft: "8px" }}>分</div>
              <ErrorArea isErrorDisplay={isErrorTime} style={{ position: "absolute", bottom: "-8px", left: "41%" }}>
                <ErrorMessage>開始時間より後の時間を選択してください</ErrorMessage>
              </ErrorArea>
            </FormRow>
          </ContentArea>
        )}
      </div>
      <FooterArea>
        <CancelWrap>
          <Button type="secondary" onClick={() => onBackPage()} label="キャンセル" width="160px" />
        </CancelWrap>
        <SaveWrap>
          <Annotation>保存するまで変更内容は反映されません</Annotation>
          <Button type="primary" onClick={() => onSubmit()} label="変更内容を保存" disabled={isError} width="160px" />
        </SaveWrap>
      </FooterArea>
      <UserModal isVisible={isVisible} config={modalConfig} />
    </OverWrapBaseBoard>
  );
};

export default EditCompany;
