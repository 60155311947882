import { isJSON } from "class-validator";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import { Board, ContentArea, FooterArea, FormRow, TitleArea } from "components/LayoutParts";
import Loading from "components/Loading";
import TextareaForm from "components/TextareaForm";
import UserModal, { UserModalConfig } from "components/UserModal";
import color from "constants/color";
import font from "constants/font";
import { useFindEdgeDevice, useUpdateSetting } from "pages/AimoParking/EdgeDevice/api";
import {
  CONFIRM_MODAL_CONFIG,
  FAILED_MODAL_CONFIG,
  INITIAL_MODAL_CONFIG,
  SUCCEED_MODAL_CONFIG,
} from "pages/AimoParking/modalTemplate";
import { extractErrorCode } from "pages/AimoParking/utils/extractErrorCodeUtil";
import { formatEmptyJson } from "pages/AimoParking/utils/formatEmptyJsonUtil";

const Label = styled.div<{ top?: boolean }>`
  min-width: 200px;
  margin: 0 5px;
  padding-right: 18px;
  align-self: ${({ top }) => (top ? "flex-start" : "center")};
  margin-top: ${({ top }) => (top ? "14px" : "0")};
`;

const Wrap = styled.div`
  margin-top: 10px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  flex: 1;
`;

const CancelWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
`;

const SaveWrap = styled(CancelWrap)`
  align-items: center;
  justify-content: flex-end;
`;
const Annotation = styled.span`
  font-size: ${font.size12};
  color: ${color.text};
  margin-right: 16px;
`;

const EditEdgeDevice = () => {
  const [setting, setSetting] = useState<string>();
  const [modalConfig, setModalConfig] = useState<UserModalConfig>(INITIAL_MODAL_CONFIG);
  const [isVisible, setIsVisible] = useState(false);
  const [settingErrorMessage, setSettingErrorMessage] = useState<string>();

  const navigate = useNavigate();
  const { id: edgeDeviceId } = useParams();
  const { data: edgeDevice } = useFindEdgeDevice({ edgeDeviceId: edgeDeviceId as string, revalidateOnFocus: false });
  const { updateSetting } = useUpdateSetting();

  const isErrorSetting = !isJSON(setting) || setting === "" || formatEmptyJson(setting ?? "{}") === "{}";
  const isChangedSetting = setting !== JSON.stringify(edgeDevice?.setting, null, 2);
  const canSubmit = !isErrorSetting && isChangedSetting;

  const handleChangedSetting = (e: React.ChangeEvent<HTMLTextAreaElement>) => setSetting(e.target.value);
  const handleNavigateEdgeDeviceDetail = () =>
    edgeDevice?.serialNumber && navigate(`/aimo_parking/edge_device/${edgeDeviceId}`);

  const onSubmit = useCallback(async () => {
    if (!edgeDevice) return;
    const response = await updateSetting({
      edgeDeviceId: edgeDevice.id,
      setting: JSON.parse(setting ?? ""),
      serialNumber: edgeDevice.serialNumber,
    }).catch((e) => {
      const error = extractErrorCode(e);
      error?.errorMessage && setSettingErrorMessage(error.errorMessage);
      setModalConfig(FAILED_MODAL_CONFIG({ onClose: () => setIsVisible(false) }));
      setIsVisible(true);
      throw e;
    });

    if (response.status === 200) {
      setSettingErrorMessage(undefined);
      setModalConfig(
        SUCCEED_MODAL_CONFIG({
          message: "変更しました",
          onClose: () => navigate(`/aimo_parking/edge_device/${edgeDeviceId}`),
        })
      );
      setIsVisible(true);
    }
  }, [updateSetting, navigate, setModalConfig, setting, setIsVisible, edgeDevice, edgeDeviceId]);

  const handleOpenSubmitModal = useCallback(() => {
    setModalConfig(
      CONFIRM_MODAL_CONFIG({
        negative: () => setIsVisible(false),
        positive: onSubmit,
        message: "設定JSONを変更しますか？",
      })
    );
    setIsVisible(true);
  }, [onSubmit, setModalConfig, setIsVisible]);

  const setUp = useCallback(() => {
    setSetting(JSON.stringify(edgeDevice?.setting, null, 2) as string);
  }, [edgeDevice]);

  useEffect(() => {
    if (!edgeDevice) return;
    setUp();
  }, [setUp, edgeDevice]);

  return (
    <Board>
      <TitleArea>
        <Breadcrumb
          currentPageName={`設定JSONの変更`}
          breadcrumbItems={[
            {
              pageName: `エッジデバイス`,
              onClick: () => navigate(`/aimo_parking/edge_device`),
            },
            {
              pageName: `${edgeDevice?.serialNumber ?? "読み込み中"}`,
              onClick: handleNavigateEdgeDeviceDetail,
            },
          ]}
        />
      </TitleArea>
      {edgeDevice ? (
        <Wrap>
          <ContentArea>
            <FormRow>
              <Label top>設定JSON</Label>
              <TextareaForm
                value={setting}
                onChange={handleChangedSetting}
                minHeight={400}
                width="812px"
                errorMessage={settingErrorMessage ?? "設定JSONを入力してください"}
                isError={!!settingErrorMessage || isErrorSetting}
                placeholder="設定JSONを入力"
              />
            </FormRow>
          </ContentArea>
          <FooterArea>
            <CancelWrap>
              <Button
                type="secondary"
                onClick={() => navigate(`/aimo_parking/edge_device/${edgeDeviceId}`)}
                label="キャンセル"
                width="160px"
              />
            </CancelWrap>
            <SaveWrap>
              <Annotation>変更するまで入力内容は反映されません</Annotation>
              <Button
                type="primary"
                onClick={handleOpenSubmitModal}
                label="設定JSONを変更"
                disabled={!canSubmit}
                width="160px"
              />
            </SaveWrap>
          </FooterArea>
        </Wrap>
      ) : (
        <Loading />
      )}
      <UserModal isVisible={isVisible} config={modalConfig} />
    </Board>
  );
};

export default EditEdgeDevice;
